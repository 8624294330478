import Head from 'next/head';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { GoogleTagManager } from '@next/third-parties/google';

import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { useEffect, type ReactElement, type ReactNode } from 'react';

import '@gate-academy/shared/dist/styles/base.css';
import '@gate-academy/shared/dist/styles/vars.css';
import '../styles/global.css';

import ProgressBar from '../components/progress-bar/progress-bar.component';
import ToastContainer from '../components/toast-container/toast-container.component';

export type NextPageWithLayout<P = unknown, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & { Component: NextPageWithLayout };

const loadResizeObserver = async () => {
  if (typeof window.ResizeObserver === 'undefined') {
    const polyfill = await import('@juggle/resize-observer');
    window.ResizeObserver = polyfill.ResizeObserver;
  }
};

const queryClient = new QueryClient();
function MyApp({ Component, pageProps }: AppPropsWithLayout): ReactElement {
  const getLayout = Component.getLayout ?? ((page) => page);

  useEffect(() => {
    loadResizeObserver();
  }, []);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>

      <ProgressBar />

      <ToastContainer />

      <QueryClientProvider client={queryClient}>
        {getLayout(<Component {...pageProps} />)}

        <ReactQueryDevtools />
      </QueryClientProvider>

      <GoogleTagManager gtmId="AW-11282823026" />
    </>
  );
}

export default MyApp;
